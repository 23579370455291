import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./components/pages/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "about-us",
    loadChildren: () =>
      import("./components/pages/about-us/about-us.module").then(
        (m) => m.AboutUsModule
      ),
  },
  {
    path: "contact-us",
    loadChildren: () =>
      import("./components/pages/contact-us/contact-us.module").then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: "careers",
    loadChildren: () =>
      import("./components/pages/career/career.module").then(
        (m) => m.CareerModule
      ),
  },
  {
    path: "developers",
    loadChildren: () =>
      import("./components/pages/developers/developers.module").then(
        (m) => m.DevelopersModule
      ),
  },
  {
    path: "our-network",
    loadChildren: () =>
      import("./components/pages/our-network/our-network.module").then(
        (m) => m.OurNetworkModule
      ),
  },
  {
    path: "be-our-agent",
    loadChildren: () =>
      import("./components/pages/become-agemnt/become-agemnt.module").then(
        (m) => m.BecomeAgemntModule
      ),
  },
  {
    path: "send-and-receive-money",
    loadChildren: () =>
      import(
        "./components/pages/send-receive-money/send-receive-money.module"
      ).then((m) => m.SendReceiveMoneyModule),
  },
  {
    path: "global-payout-network",
    loadChildren: () =>
      import(
        "./components/pages/global-payout-network/global-payout-network.module"
      ).then((m) => m.globalpayoutnetworkModule),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./components/pages/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: "term-and-conditions",
    loadChildren: () =>
      import(
        "./components/pages/term-and-conditions/term-and-conditions.module"
      ).then((m) => m.TermAndConditionsModule),
  },
  {
    path: "cookie-policy",
    loadChildren: () =>
      import("./components/pages/cookie-policy/cookie-policy.module").then(
        (m) => m.CookiePolicyModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
